export function allAnimations($root) {
  animationContent($root)
  loginAnimation($root)
  animationDots($root)
}

export function animationContent($root, dataTag = 'animation') {
  const elemsToAnimate = $root.querySelectorAll(`[data-${dataTag}]`)
  elemsToAnimate.forEach(el => {
    if (!el.classList.contains(`${dataTag}__animated`)) {
      const animation = el.dataset[dataTag]
      if (dataTag === 'end') {
        el.style.animationDelay = '0s'
      } else {
        const delay = el.dataset.delay
        el.style.animationDelay = delay + 's'
      }
      el.classList.add(`animation__${animation}`, `${dataTag}__animated`)
    }
  })
}

function animationDots($root) {
  const dots = $root.querySelectorAll('.dot-item')
  dots.forEach(dotAnimation)
}

function dotAnimation(dotItem) {
  const circle = dotItem.querySelector('.ringring')
  const delay = dotItem.dataset.delay * 1000
  
  // Задержка перед запуском анимации
  setTimeout(() => {
    animateDot(circle)
    // Интервал до след анимации
    setInterval(() => {
      animateDot(circle)
    }, 23000)
  }, delay)
}

function loginAnimation($root) {
  const $login = $root.querySelector('.login')
  const $loginText = $login.querySelector('span')

  console.log($loginText)
  $login.addEventListener('mousemove', e => {
    console.log('$login.width', $login.offsetWidth)
    console.log('$login.getBoundingClientRect().left', $login.getBoundingClientRect().left)
    const deltaX = -($login.getBoundingClientRect().left + ($login.offsetWidth / 2)- e.clientX) * 0.8 + 'px'
    const deltaY = -($login.getBoundingClientRect().top + ($login.offsetHeight / 2)- e.clientY) * 0.8 + 'px'
    $loginText.style.transform = `translate(${deltaX}, ${deltaY})`
  })
  $login.addEventListener('mouseleave',e => {
    $login.style.transform = `translate(0, 0)`
  })
}

function animateDot(circle) {
  circle.classList.add('animation__dots')
  setTimeout(() => {
    circle.classList.remove('animation__dots')
  }, 2500)
}
