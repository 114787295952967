function createButton(title, link, classes, size, delay='0') {
  return `
      <a 
        data-animation="inOpacity" 
        data-end="outOpacity" 
        data-delay="${delay}" 
        href="${link}" 
        class="button ${size ? size : ''} ${classes ? classes : ''}"
      >
        ${title}
      </a>
  `
}

export const homeHtml = () => {
  const newElement = document.createElement('div')
  newElement.classList.add('content__item')
  newElement.innerHTML = `
    <div class="hidden-y">
      <h1 data-animation="toTop" data-end="toBot" data-delay="0.2"><span class="selected">A</span>rmada App</h1>
    </div>
    <div class="hidden-y">
      <p data-animation="toTop" data-end="toBot" class="subtitle" data-delay="0.4">mobile advertising agency</p>
    </div>
    <div>
      <a data-animation="inOpacity" data-end="outOpacity" href="http://armadaapp.affise.com/signup" class="button" data-delay="1.2">Become a partner</a>
    </div>
    <div class="footer__item hidden-x mobile-visible">
      <div class="footer__item hidden-x h4">
        <a 
          href="#publishers" 
          data-animation="toRight"
          data-end="fromRight"
          data-delay="1.4"  
          class="navigation__item">
          <span>for publishers</span>
        </a>
      </div>
      <div class="footer__item hidden-x h4 ml0">
        <a 
          href="#advertisers" 
          data-animation="toRight" 
          data-end="fromRight"
          data-delay="1.6"  
          class="navigation__item">
          <span>for advertisers</span>
        </a>
      </div>
    </div>
  `
  return newElement
}

export const publishersHtml = () => {
  const newElement = document.createElement('div')
  newElement.classList.add('content__item')
  newElement.innerHTML = `
    <div class="flex">
      <div class="left">
        <div class="hidden-y">
          <h2 data-animation="toTop" data-end="toBot">Hey <span class="selected">P</span>ublishers</h2>
        </div>
        <div class="hidden-y">
          <p data-animation="toTop" class="desc" data-end="toBot" data-delay="0.2">We are the leaders in HQ traffic. 
          Our record is second to none.</p>
        </div>
        <div>
          ${createButton('Become a publisher', 'http://armadaapp.affise.com/signup', 'about__btn desktop-visible-flex', null, '2')}
        </div>
      </div>
      <div class="right">
        <div class="hidden-y">
          <p data-animation="fromTop"  data-end="fromBot"  class="list-title" data-delay="0.6">We have</p>
        </div>
        <ul>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="0.8">- Exclusive offers with highest PO</li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1">- On-time payments </li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1.2"> - Technical Support 24/7</li>
          </div>
        </ul>
      </div>
      ${createButton('Become an publisher', 'http://armadaapp.affise.com/signup', 'about__btn mobile-visible-flex', null, '2')}

    </div>
  `
  return newElement
}

export const advertisersHtml = () => {
  const newElement = document.createElement('div')
  newElement.classList.add('content__item')
  newElement.innerHTML = `
    <div class="flex">
      <div class="left">
        <div class="hidden-y">
        <h2 data-animation="toTop" data-end="toBot">Hey <span class="selected">A</span>dvertisers</h2>
        </div>
        <div class="hidden-y">
        <p data-animation="toTop" data-end="toBot" data-delay="0.2" class="desc">We build our cooperation on trust and mutual understanding.</p>
        </div>
        <div>
          ${createButton('Become an advertisers', 'http://armadaapp.affise.com/signup', 'about__btn desktop-visible-flex', null, '2')}
        </div>
      </div>
      <div class="right mr-115">
        <div class="hidden-y">
          <p class="list-title" data-animation="toTop" data-end="toBot" data-delay="0.6">We have:</p>
        </div>
        <div class="hidden-y">
        <ul>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="0.8"> - Maximize volume</li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1">- Boost your traffic</li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1.2">- Exceed your ROI goals</li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1.4">- Increase Online Sales</li>
          </div>
          <div class="hidden-y">
            <li data-animation="fromTop" data-end="fromBot" data-delay="1.6">- Anti-fraud system </li>
          </div>
        </ul>
        </div>
        ${createButton('Become an advertisers', 'http://armadaapp.affise.com/signup', 'about__btn mobile-visible-flex', null, '2')}

      </div>
    </div>
  `
  return newElement
} 

export const aboutHtml = () => {
  const newElement = document.createElement('div')
  newElement.classList.add('content__item')
  newElement.innerHTML = `
    <div class="hidden-y">
      <div class="withArrowTitle">
        <div class="hidden-x hidden-y">
        <h2  data-animation="toRight" data-end="fromRight"><span class="selected">A</span>bout us</h2>
        </div>
        <div class="hidden-x">
        <img data-animation="toRight" data-end="fromRight" data-delay="0.4" src="./assets/img/Arrow.png" alt="">
        </div>
        <div class="hidden-x">
        <p data-animation="toRight" data-end="fromRight" data-delay="0.8">We are an ad technology company working primarily in the mobile app market.</p>
        </div>
      </div>
    </div>
    <div class="about__block">
      <div class="about__block-text">
        <div class="hidden-y">
          <p data-animation="fromTop" data-end="fromBot" data-delay="1.2" class="text-content">Our focus is to deliver engaged users. We direct high quality traffic to our advertisers and app developers. We build long-lasting and trust-based relationships with our partners. </p>
        </div>
        <div class="hidden-y">
          <p data-animation="fromTop" data-end="fromBot" data-delay="1.6" class="text-content">Optimized returns on our partnership. We provide a flexible payout system. You can monitor campaigns easily with real-time statistics.</p>
        </div>
      </div>
      <div class="flex-bot">
        ${createButton('Become a partner', 'http://armadaapp.affise.com/signup', 'about__btn', 'small', '2')}
      </div>
    </div>
   
  `
  return newElement
}

export const contactsHtml = () => {
  const newElement = document.createElement('div')
  newElement.classList.add('content__item')
  newElement.innerHTML = `
    <div class="hidden-y text-center">
      <h2 data-animation="toTop" data-end="toBot"><span class="selected">C</span>ontact us</h2>
    </div>
    <div class="contact__blocks">
      <div class="hidden-y">
        <div class="contact__block text-center" data-animation="toTop" data-end="toBot" data-delay="0">
          <p>Head of Advertisers Relations:</p>
          <p><a href="mailto:hanna@armadaapp.com" class="selected">hanna@armadaapp.com</a></p>
          ${createButton('Become an advertiser', 'http://armadaapp.affise.com/signup', 'about__btn', 'contactsBTN', '1.4')}
        </div>
      </div>
      <div class="poloska"  data-animation="inOpacity" data-end="outOpacity" data-delay="0.4"></div>
      <div class="hidden-y">
        <div class="contact__block text-center" data-animation="toTop" data-end="toBot" data-delay="0.2">
          <p>Head of Publishers Relations:</p>
          <p><a href="mailto:odri@armadaapp.com" class="selected">odri@armadaapp.com</a></p>
          ${createButton('Become an publisher', 'http://armadaapp.affise.com/signup', 'about__btn', 'contactsBTN', '1.4')}
        </div>
      </div>
    </div>
    <div class="hidden-y text-center">
      <p 
        class="text-center contact-text" 
        data-animation="toTop" 
        data-end="toBot" 
        data-delay="0.8"
      >
        Armada App 4 Crown Mill, 118 Chilton Street, Bridgwater, England, TA6 3HY
      </p>
    </div>
  `
  return newElement
}

