import {
  homeHtml,
  publishersHtml,
  advertisersHtml,
  aboutHtml,
  contactsHtml
} from './routes.markup'

// key -- route
// value -- html
export const routes = {
  '': homeHtml,
  'publishers': publishersHtml,
  'advertisers': advertisersHtml,
  'about': aboutHtml,
  'contacts': contactsHtml
}
