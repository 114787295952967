//import {loginAnimation} from './login.animation'

export function cursor($root) {
  const body = document.querySelector('body')
  
  const $cursor = cursorCreate(body)
  // Движение курсора
  cursorMove($cursor, $root, body)

}

function cursorCreate(body) {
  const $cursor = document.createElement('div')
  const $beforeElement = body.querySelector('#app')

  $cursor.classList.add('cursor', 'cursor-hidden')

  const cursor = body.insertBefore($cursor, $beforeElement)

  return cursor
}

function cursorMove($cursor, $root, body) {
  const $login = $root.querySelector('.login')
  const $loginText = $login.querySelector('span')

  let timeout
  body.addEventListener('mousemove', e =>{
    // Очищаем таймер
    clearTimeout(timeout)
    // Движение курсора
    mouseMove(e, $cursor, $login, $loginText)
    // Таймер
    timeout = setTimeout(() => {
      $cursor.classList.add('cursor-hidden')
    }, 5000)
  })
}

function mouseMove(event, $cursor, $login, $loginText) {
  // константы которые нужны для условия
  const appWidth = document.documentElement.offsetWidth
  const appHeight = document.documentElement.offsetHeight
  const cursorWidth = $cursor.offsetWidth 
  const cursorHeight = $cursor.offsetHeight

  const cursorXcoords = event.clientX - (cursorWidth / 2)
  const cursorYcoords = event.clientY - (cursorHeight / 2)

  // Условия при которых появляется курсор
  const condition =
    cursorXcoords + cursorWidth < appWidth
    && cursorYcoords + cursorHeight < appHeight
    && cursorXcoords > 0
    && cursorYcoords > 0

  if (condition) {
    $cursor.classList.remove('cursor-hidden')

    // Изменение позиции курсора
    $cursor.style.top = cursorYcoords  + 'px'
    $cursor.style.left = cursorXcoords + 'px'

    hoveredElementCheck(event, $cursor, $login, $loginText)
  } else {
    // Прятать курсор, если условия не соблюдены
    $cursor.classList.add('cursor-hidden')
  }
}

function hoveredElementCheck(event, $cursor, $login, $loginText) {
  if (event.target === $login || event.target === $loginText) {
    // loginAnimation($cursor, $login, $loginText)
    $cursor.classList.add('big')
  } else {
    $loginText.style.transform = `translate(0, 0)`
    $cursor.classList.remove('big')
    $login.classList.remove('login-visible')
  }
}
