import {routes} from './routes'
import {animationContent} from '../animations/animations'

export function routing($root) {
  let prevElem = null
  let isReady = true
  function changePrevElem(newElem) {
    prevElem = newElem
  }

  checkHash($root, prevElem, changePrevElem)
  window.addEventListener('hashchange', e => {
    if (isReady) {
      isReady = false
      checkHash($root, prevElem, changePrevElem)
      setTimeout(() => isReady = true, 1400)
    } else {
      window.location.href = e.oldURL
    }
  })
}

async function checkHash($root, prevElem, changePrevElem) {
  const $output = $root.querySelector('.content') 
  const $hideBg = $root.querySelector('.bg-hide')
  window.$output = $output
  const currentHash = window.location.hash.slice(1)

  const newElem = routes[currentHash]()

  if (Object.keys(routes).includes(currentHash)){
    currentHash === ''
      ? $hideBg.classList.add('bgHidden')
      : $hideBg.classList.remove('bgHidden')
    setAnimationsOnChange(newElem, prevElem, changePrevElem, $root)
  } else {
    window.location.hash = ''
  }
}

async function setAnimationsOnChange(newElem, prevElem, changePrevElem, $root) {
  if (prevElem) {
    animationContent($root, 'end')
    // removing
    await new Promise(resolve => {
      setTimeout(() => {
        $output.removeChild(prevElem)
        resolve()
      }, 700)
    })
  }
  $output.appendChild(newElem)
  animationContent($root)
  
  changePrevElem(newElem)
}
