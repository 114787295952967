import {cursor} from './cursor/cursor'
import {routing} from './routing/routing'
import { allAnimations } from './animations/animations'

const $root = document.querySelector('#app')

function initApp() {
  $root.classList.add('bg-map')
  document.querySelector('.app-content').classList.remove('hidden')
  if (document.body.clientWidth > 991) {
    cursor($root)
  }
  allAnimations($root)
  routing($root)
  document.querySelector('.preloader').classList.add('preloader-hidden')
  document.querySelector('.bg-dots').classList.remove('preloading')
  
}

window.onaload = initApp()